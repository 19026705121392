import axios from "axios";

//#### LIVE SERVER CONFIGS #### //
export const accountsConfig = axios.create({
    baseURL: "https://api.startup-school.talrop.com",
});
export const yiaaiConfig = axios.create({
    baseURL: "https://api.yia.ai/api/v1/",
});
export const communityConfig = axios.create({
    baseURL: "https://api.startup-school.talrop.com/api/v1/",
});


// #### DEVELOPER SERVER CONFIGS #### //
// export const accountsConfig = axios.create({
//     baseURL: "https://developers-api.startupschool.talrop.com",
// });
// export const yiaaiConfig = axios.create({
//     baseURL: "https://developers-yiaai.talrop.com/api/v1/",
// });
// export const talropAccounts = axios.create({
//     baseURL: "https://developers-accounts.talrop.com/",
// });
// export const communityConfig = axios.create({
//     baseURL: "https://developers-api.startupschool.talrop.com/api/v1/",
// });


// #### LOCAL IP CONFIGS #### //
// export const accountsConfig = axios.create({
//     baseURL: "http://localhost:8001/",
// });
// export const yiaaiConfig = axios.create({
//     baseURL: "http://localhost:8001/api/v1/",
// });
// export const communityConfig = axios.create({
//     baseURL: "http://localhost:8001/api/v1/",
// });
