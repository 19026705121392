import React, { useContext, useEffect, lazy } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { Context } from "../../context/store";
import TefunRouter from "../routers/app/TefunRouter";
import { yiaaiConfig } from "../../helpers/axiosConfig";
import TefunCertificate from "../../tefun/TefunCertificate";
import StartupSchoolLandingPage from "../../web/screens/StartupSchoolLandingPage";
import CurrentTopicPage from "../../web/screens/CurrentTopicPage";
import SpotLight from "../../web/screens/Spotlight";
import Tefun404 from "../../tefun/Tefun404";

const TermsAndConditions = lazy(() =>
    import("../../../general/TermsAndConditions")
);

const PageLoader = lazy(() => import("../../includes/PageLoader"));
const LandingPage = lazy(() => import("../../web/screens/LandingPage"));

function AppRouter() {
    const { state, dispatch } = useContext(Context);
    const { is_verified, access_token } = state.user_data;
    const { isLoading, examCompleted } = state.tefunData;

    return !isLoading ? (
        <>
            <Routes>
                <Route>
                    
                    <Route path="/*" element={<Navigate to="/dashboard/" />} />
                    <Route
                        path="dashboard/"
                        element={<StartupSchoolLandingPage />}
                    />

                    <Route
                        path=":course_slug/:topic_pk/"
                        element={<CurrentTopicPage />}
                    />
                </Route>
            </Routes>
        </>
    ) : (
        <PageLoader />
    );
}

export default AppRouter;
