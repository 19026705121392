import React, { useEffect, useContext } from "react";
import Footer from "./Footer";
import styled from "styled-components";
import Spotlight from "./Spotlight";
import Topbar from "./Topbar";
import { Context } from "../../context/store";

function LandingPage() {
    const { dispatch } = useContext(Context);
    useEffect(() => {
        dispatch({
            type: "UPDATE_HEADER_HIDDEN",
            value: true,
        });

        return () => {
            dispatch({
                type: "UPDATE_HEADER_HIDDEN",
                value: false,
            });
        };
    }, []);

    return (
        <Container>
            <Topbar />
            <Spotlight />
        </Container>
    );
}

export default LandingPage;

const Container = styled.div`
    width: 100%;
    overflow-x: unset;
    @media (max-width: 1080px) {
        overflow-x: hidden;
    }
`;

// const Container = styled.section``
