import React, { useContext, useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import styled from "styled-components";
import { Context } from "../../components/context/store";
import LoginModal from "../../components/includes/login/modals/LoginModal";
import PasswordModal from "../../components/includes/login/modals/PasswordModal";


const ModalCover = () => {
    const [searchParams, setSearchParams] = useSearchParams();
    const {
        state: {
            user_validate: { username },
        },
        dispatch,
    } = useContext(Context);
    const action = searchParams.get("action");
    const [ismodal, setIsmodal] = useState(false);
    useEffect(() => {
        if (!username) {
            setSearchParams({action:"login"})
            if (
                action === "password" ||
                action === "verify" ||
                action === "password"
            )
                setSearchParams({ action: "login" });
        }

        if (
            action === "login" ||
            action === "verify" ||
            action === "password" 
        ) {
            setIsmodal(true);
        } else {
            setIsmodal(false);
        }
    }, [action]);

    return (
        <Content ismodal={ismodal}>
            <>
                {action === "login" ? (
                    <LoginModal />
                ) : action === "password" ? (
                    <PasswordModal />
                ) : null}
                <CloseButton
                    onClick={() => {
                        setIsmodal(false);
                        setSearchParams();
                    }}
                >
                    <img
                        src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/29-09-2021/close.svg"
                        alt="icon"
                    />
                </CloseButton>
            </>
        </Content>
    );
};

export default ModalCover;

const Content = styled.div`
    width: ${({ ismodal }) => (ismodal ? "600px" : "0")};
    opacity: ${({ ismodal }) => (ismodal ? "1" : "0")};
    height: 100vh;
    background: #fff;
    transition: 0.4s ease;
    position: fixed;
    right: 0;
    top: 0;
    z-index: 200;
    @media (max-width: 640px) {
        width: ${({ ismodal }) => (ismodal ? "428px" : "0")};
    }
    @media (max-width: 480px) {
        width: ${({ ismodal }) => (ismodal ? "359px" : "0")};
    }
    @media (max-width: 400px) {
        width: ${({ ismodal }) => (ismodal ? "100%" : "0")};
    }
`;
const CloseButton = styled.div`
    border-radius: 50%;
    padding: 6px;
    background: #fff;
    width: 23px;
    height: 23px;
    position: absolute;
    top: 4px;
    left: -28px;
    cursor: pointer;
    z-index: 501;
    @media (max-width: 400px) {
        left: 6px;
        border: 1px solid #4caf50;
        background: transparent;
    }
    @media (max-width: 360px) {
        left: 6px;
    }
    img {
        width: 100%;
        display: block;
    }
`;
