import React, { Suspense, lazy } from "react";
import { Route, Routes } from "react-router-dom";
import SpotLight from "../../web/screens/Spotlight";

function AuthRouter() {
    return (
        <>
            <Suspense>
                {/* <CoverPage /> */}
                <Routes>
                    <Route>
                        <Route path="" element={<SpotLight />} />
                    </Route>
                </Routes>
            </Suspense>
        </>
    );
}

export default AuthRouter;
