import React, { useContext, useEffect, useState } from "react";
import { Link, useParams, useNavigate, useLocation } from "react-router-dom";
import styled from "styled-components";
import PlaceHolder from "../../../general/PlaceHolder";
import VideoPlayer from "../../applications/video-player/src/VideoPlayer";
import { Context } from "../../context/store";
import { communityConfig } from "../../helpers/axiosConfig";
import TalropEdtechHelmet from "../../helpers/TalropEdtechHelmet";
import Header from "../../includes/Header";
import StartupSchoolLessonBar from "../../tefun/StartupSchoolLessonBar";
import TefunLoader from "../../tefun/TefunLoader";
import CourseCompleteModal from "../../tefun/modals/CourseCompleteModal";

function CurrentTopicPage() {
    const [isbutton, setButton] = useState(false);
    const [isButtonLoading, setIsButtonLoading] = useState(false);
    const [selectedLesson, setSelectedLesson] = useState({});
    const [topicLoading, setTopicLoading] = useState(false);
    const [isLoading, setLoading] = useState(false);
    const [data, setData] = useState([]);
    const [isModal, setModal] = useState(false);
    const { topic_pk } = useParams();
    const [allData, setAllData] = useState([]);

    const { state, dispatch } = useContext(Context);
    const slug = localStorage.getItem("selected_course");

    const user_data = state.user_data;
    const playerSettings = {
        currentSpeed: 1,
        qualityLevel: -1,
        quality: {
            height: "Auto",
        },
    };
    const videoJsOptions = {
        autoplay: true,
        controls: true,
    };
    const slugData = useLocation();

    useEffect(() => {
        dispatch({
            type: "UPDATE_TEFUN_DATA",
            payload: {
                selected_course: slugData.state,
            },
        });
    }, []);
    const navigate = useNavigate();
    useEffect(() => {
        const renderData = () => {
            setLoading(true);
            const { access_token } = user_data;
            if (slug) {
                communityConfig
                    .get(`courses/course-lesson/${slug}`, {
                        headers: {
                            Authorization: `Bearer ${access_token}`,
                        },
                    })
                    .then((response) => {
                        const { StatusCode, data } = response.data;
                        if (StatusCode === 6000) {
                            setAllData(response.data);
                            setData(response.data.data);
                        } else if (StatusCode === 6001) {
                        }
                    })
                    .catch((error) => {
                        console.log(error);
                        // handleExamBannerLoading();
                    });
            }
        };
        renderData();
    }, [topic_pk, slug]);

    let count = 1;
    const markAsCompleted = () => {
        count = count + 1;
        setIsButtonLoading(true);
        const { access_token } = user_data;
        communityConfig
            .post(
                `courses/course/mark-as-complete/${topic_pk}/`,
                {},
                {
                    headers: {
                        Authorization: `Bearer ${access_token}`,
                    },
                }
            )

            .then((response) => {
                const { StatusCode, data } = response.data;
                if (StatusCode === 6000) {
                    setIsButtonLoading(false);
                    navigate(`/${slug}/${data.next_topic_pk}`);
                }
                if (data.is_course_completed) {
                    setModal(true);
                }
            })
            .catch((error) => {
                console.log(error);
                setIsButtonLoading(false);
            });
    };

    const filtered =
        data.length > 0 &&
        data
            .map((obj) => {
                return Object.assign({}, obj, {
                    topics: obj.topics.filter((el) => el.id === topic_pk),
                });
            })
            .filter((obj) => obj.topics.length > 0);

    let videoLesson = filtered.length > 0 && filtered[0].topics[0];

    return (
        <>
            <Header />
            <Containers id="main">
                <>
                    <InnerWrapper>
                        <PrimeContainer>
                            <CourseCompleteModal
                                isModal={isModal}
                                setModal={setModal}
                            />

                            <PrimeTop>
                                <Contains>
                                    <InnerContainer>
                                        <>
                                            <PlaceHolder
                                                isLoading={topicLoading}
                                                paddingTop="56.25%"
                                            />

                                            <PlayerContainer>
                                                <VideoPlayer
                                                    playerSettings={
                                                        playerSettings
                                                    }
                                                    {...videoJsOptions}
                                                    source={
                                                        videoLesson.video_url
                                                    }
                                                />
                                            </PlayerContainer>
                                        </>

                                        <TitleWrapper>
                                            <Left>
                                                <Topic>
                                                    {videoLesson?.lesson_name}
                                                </Topic>
                                                <SubTopic>
                                                    {videoLesson?.name}
                                                </SubTopic>
                                            </Left>
                                            {filtered.length > 0 &&
                                            filtered[0].topics[0].status ===
                                                "pending" ? (
                                                <Button
                                                    onClick={markAsCompleted}
                                                >
                                                    {isButtonLoading ? (
                                                        <TefunLoader />
                                                    ) : (
                                                        <>
                                                            <IconContainer
                                                                onClick={() => {
                                                                    setModal(
                                                                        true
                                                                    );
                                                                }}
                                                            >
                                                                <Icon
                                                                    src={
                                                                        "https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/tefun/14-01-2022/icon-mark.svg"
                                                                    }
                                                                    alt="Image"
                                                                />
                                                            </IconContainer>
                                                            <p>
                                                                Mark as Complete
                                                            </p>
                                                        </>
                                                    )}
                                                </Button>
                                            ) : null}
                                        </TitleWrapper>
                                    </InnerContainer>
                                </Contains>
                            </PrimeTop>
                            <PrimeBottom>
                                <StartupSchoolLessonBar
                                    selectedLesson={selectedLesson}
                                    setSelectedLesson={setSelectedLesson}
                                    topic_pk={topic_pk}
                                    lessonData={data}
                                    setLessonData={setData}
                                    setAllData={setAllData}
                                    allData={allData}
                                    filteredData={filtered}
                                    lessonSlug={slug}
                                />
                            </PrimeBottom>
                        </PrimeContainer>
                    </InnerWrapper>
                </>
            </Containers>
        </>
    );
}

export default CurrentTopicPage;
const PlayerContainer = styled.div`
    @media all and (max-width: 480px) {
        position: fixed;
        left: 0;
        right: 0;
        top: 59px;
        z-index: 7;
    }
`;
//locked overlay styless
const VideoContainerOverlay = styled.div`
    filter: brightness(0.5);
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.5);
    h3 {
        color: #000;
        font-family: "gordita_medium";
        font-size: 24px;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        position: absolute;
    }
    img {
        width: 100%;
        display: block;
    }
`;
const OverlayContainer = styled.div`
    background: #fff;
    z-index: 2;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    border-radius: 10px;
    overflow: hidden;
    /* color: #fff; */
    display: flex;
`;
const ContentContainer = styled.div`
    margin: auto;
    text-align: center;
    z-index: 3;
    cursor: default;
    @media all and (max-width: 640px) {
        width: 100%;
        padding: 20px 20px;
    }
    @media all and (max-width: 420px) {
        padding: 12px 12px;
    }
`;
const ImageContainer = styled.div`
    width: 30%;
    margin: 0 auto 20px;
    @media all and (max-width: 768px) {
        width: 57px;
    }
    @media all and (max-width: 640px) {
        width: 40px;
        margin: 0 auto 8px;
    }
    @media all and (max-width: 540px) {
        width: 33px;
    }
    @media all and (max-width: 420px) {
        width: 29px;
    }
    @media all and (max-width: 360px) {
        margin: 0 auto 0px;
    }
`;
const TefunImage = styled.img`
    display: block;
    width: 100%;
`;
const Title = styled.h3`
    font-family: "gordita_medium";
    margin-bottom: 10px;
    color: #fff;
    @media all and (max-width: 640px) {
        margin-bottom: 5px;
    }
    @media all and (max-width: 420px) {
        font-size: 16px;
        margin-bottom: 1px;
    }
    @media all and (max-width: 360px) {
        margin-bottom: 0px;
        font-size: 14px;
    }
`;
const Descriptions = styled.p`
    margin-bottom: 20px;
    color: #fff;
    font-size: 15px;
    @media all and (max-width: 640px) {
        margin-bottom: 0px;
    }
    @media all and (max-width: 420px) {
        font-size: 14px;
        line-height: 1.3em !important;
    }
    @media all and (max-width: 360px) {
        font-size: 12px;
        line-height: 1.2em !important;
    }
`;
const Br = styled.br`
    @media all and (max-width: 640px) {
        display: none;
    }
`;

// banner styless
const UpdateBanner = styled.div`
    background-color: #fff3ec;
    padding: 14px 14px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 7px;
    z-index: 8;
    opacity: 1;
    animation: fade 300ms ease 0ms;
    position: relative;
    img {
        width: 100%;
        display: block;
    }
    span {
        display: none;
        cursor: pointer;
        width: 20px;
        position: absolute;
        right: 2px;
        top: 1px;
        background: #fff;
        border-radius: 50%;
        padding: 5px;
        @media only screen and (max-width: 480px) {
            display: block;
        }
    }
    @keyframes fade {
        0% {
            opacity: 0;
        }
        100% {
            opacity: 1;
        }
    }
    @media all and (max-width: 768px) {
        padding: 16px 16px;
        flex-direction: column;
        align-items: flex-start;
    }
    @media all and (max-width: 480px) {
        position: fixed;
        left: 0;
        right: 0;
        bottom: 0px;
        border-radius: 0px;
        padding: 13px 16px;
    }
`;
const BannerLeftContainer = styled.div`
    display: flex;
    width: 80%;
    margin-right: 6px;
    p {
        font-size: 15px;
        @media all and(max-width:480px) {
            line-height: 1.5em;
        }
        @media all and(max-width:420px) {
            font-size: 14px;
        }
    }
    @media all and (max-width: 980px) {
        width: 62%;
    }
    @media all and (max-width: 768px) {
        flex-direction: column;
        width: 100%;
        margin-right: 0px;
    }
`;
const BannerIconContainer = styled.div`
    width: 20px;
    margin-right: 10px;
    display: flex;
    @media all and (max-width: 768px) {
        margin-bottom: 10px;
        margin-right: 0px;
    }
    @media all and (max-width: 640px) {
        margin-right: 0px;
        margin-bottom: 10px;
    }
    img {
        display: block;
        width: 100%;
    }
    @media all and (max-width: 480px) {
        display: none;
    }
`;
const BannerHead = styled.h3`
    color: #e4794b;
    font-size: 17px;
    font-family: "gordita_medium";
    margin-bottom: 3px;
    @media all and (max-width: 480px) {
        font-size: 15px;
    }
    @media all and (max-width: 420px) {
        /* font-size: 16px; */
    }
`;
const Para = styled.p`
    font-size: 14px;
    @media all and (max-width: 480px) {
        font-size: 13px !important;
    }
`;
const RightButton = styled(Link)`
    display: flex;
    justify-content: center;
    min-width: 115px;
    font-family: gordita_medium;
    padding: 9px 13px;
    border-radius: 6px;
    background-color: #e4794b;
    color: #fff;
    font-size: 14px;
    transition: all 0.5s linear;
    @media all and (max-width: 768px) {
        margin-top: 10px;
    }
    @media all and (max-width: 480px) {
        margin-top: 10px;
        width: 100%;
        padding: 7px 8px;
        border-radius: 5px;
        font-size: 13px;
    }
`;

const Containers = styled.div`
    padding: 21px 0;
    @media (max-width: 768px) {
        padding: 0px 0;
    }
    @media all and (max-width: 768px) {
        margin-top: 0px;
    }
`;
const InnerWrapper = styled.div`
    padding: 10px 0;
    @media (max-width: 768px) {
        padding: 0px 0;
    }
    @media all and (max-width: 768px) {
        margin-top: 0px;
    }
`;
const Contains = styled.div`
    background: #f9f9fb;
    padding: 18px 14px;
    border-radius: 6px;
    margin-top: 0px;
    height: fit-content;
    @media (max-width: 768px) {
        padding: 17px 13px;
    }
    @media (max-width: 480px) {
        padding: 0;
        background: #fff;
        /* padding: 17px 13px; */
    }
`;
const InnerContainer = styled.div``;
const LoaderContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
`;
const PrimeContainer = styled.div`
    display: grid;
    grid-template-columns: 2.3fr 1fr;
    @media (max-width: 1080px) {
        display: block;
    }
    @media (max-width: 480px) {
        padding-bottom: 100px;
    }
`;
const PrimeTop = styled.div``;
const VideoContainer = styled.div`
    padding-top: 56.25%;
    position: relative;
    margin-bottom: 26px;
    /* @media (max-width: 480px) {
        padding-top: 72.25%;
    } */
`;
const Topic = styled.span`
    font-size: 15px;
    color: #15232e7d;
    font-family: "gordita_regular";
    @media (max-width: 420px) {
        font-size: 14px;
    }
`;
const Lesson = styled.h2`
    font-size: 21px;
    font-family: "gordita_medium";
    margin-top: 1px;
    @media all and (max-width: 768px) {
        font-size: 20px;
    }
    @media all and (max-width: 480px) {
        font-size: 16px;
    }
`;
const SubTopic = styled.p`
    line-height: 1.2;
    font-size: 25px;
    font-family: "gordita_medium";
    color: #000;
    margin-top: 10px;
    @media (max-width: 980px) {
        font-size: 22px;
    }
    @media (max-width: 768px) {
        font-size: 21px;
    }
    @media (max-width: 480px) {
        font-size: 18px;
        line-height: 1.3;
    }
    @media (max-width: 420px) {
        font-size: 17px;
    }
`;
const Description = styled.p`
    line-height: 1.3;
    font-size: 14px;
    font-family: "gordita_regular";
    @media (max-width: 380px) {
        font-size: 14px;
    }
`;
const TitleWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    margin: 23px 0 0px;
    @media (max-width: 700px) {
        flex-direction: column;
    }
    @media (max-width: 480px) {
        padding-top: 200px;
    }
    @media (max-width: 360px) {
        padding-top: 130px;
    }
    @media (max-width: 320px) {
        padding-top: 120px;
    }
`;
const Left = styled.div``;
const ButtonLink = styled(Link)`
    opacity: 1;
    transition: all 0.4s ease;
    min-width: 250px;
    font-size: 15px;
    font-family: "gordita_medium";
    cursor: pointer;
    background: linear-gradient(
        272deg,
        rgba(34, 193, 195, 1) 0%,
        rgba(99, 187, 76, 1) 0%,
        rgba(24, 152, 175, 1) 100%
    );
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 12px 12px;
    border-radius: 5px;
    color: #fff;
    height: 50px;
    &.hide {
        opacity: 0;
        visibility: hidden;
    }
    &.locked {
        border-radius: 4px;
        background: linear-gradient(
            272deg,
            rgba(34, 193, 195, 1) 0%,
            rgba(99, 187, 76, 1) 0%,
            rgba(24, 152, 175, 1) 100%
        );
        color: #fff;
        padding: 10px 30px;
        text-align: center;
        font-size: 14px;
        /* display: inline-block; */
        cursor: pointer;
        display: flex;
        align-items: center;
        @media all and (max-width: 640px) {
            margin-top: 4px;
        }
        @media all and (max-width: 420px) {
            padding: 7px 14px;
        }
    }
    p {
        color: #fff;
        font-size: 15px;
        font-family: "gordita_medium";
        transform: translateY(2px);
        @media (max-width: 420px) {
            font-size: 14px;
        }
    }
    @media (max-width: 700px) {
        margin-top: 17px;
    }
    @media (max-width: 540px) {
        width: fit-content;
    }
    @media (max-width: 480px) {
        font-size: 14px;
        margin-top: 10px;
        padding: 10px 10px;
        width: fit-content;
    }
`;
const Button = styled.div`
    opacity: 1;
    transition: all 0.4s ease;
    min-width: 250px;
    font-size: 15px;
    font-family: "gordita_medium";
    cursor: pointer;
    background: linear-gradient(
        272deg,
        rgba(34, 193, 195, 1) 0%,
        rgba(99, 187, 76, 1) 0%,
        rgba(24, 152, 175, 1) 100%
    );
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 12px;
    border-radius: 7px;
    color: #fff;
    height: 45.92px;
    &.hide {
        opacity: 0;
        visibility: hidden;
    }
    &.locked {
        border-radius: 4px;
        background-color: #15bf81;
        color: #fff;
        padding: 10px 30px;
        text-align: center;
        font-size: 14px;
        cursor: pointer;
        display: flex;
        align-items: center;
        @media all and (max-width: 640px) {
            margin-top: 4px;
        }
        @media all and (max-width: 420px) {
            padding: 7px 14px;
        }
    }
    p {
        color: #fff;
        font-size: 15px;
        font-family: "gordita_medium";
        @media (max-width: 420px) {
            font-size: 14px;
        }
    }
    @media (max-width: 700px) {
        margin-top: 16px;
        width: 80px;
        min-width: 230px;

        padding: 10px;
        height: 40.92px;
    }
    @media (max-width: 540px) {
        width: fit-content;
    }
    @media (max-width: 480px) {
        font-size: 14px;
        margin-top: 10px;
        padding: 10px 10px;
        border-radius: 7px;
    }
`;
const IconContainer = styled.div`
    display: none;
    margin-right: 7px;
    @media (max-width: 420px) {
        width: 19px;
    }
`;
const Icon = styled.img`
    width: 100%;
    display: block;
`;

const PrimeBottom = styled.div`
    margin-left: 15px;
    @media (max-width: 1080px) {
        margin-left: 0px;
    }
    @media (max-width: 980px) {
        margin-left: 0px;
        height: unset;
    }
    @media (max-width: 480px) {
        margin-top: 5%;
    }
`;
